<template>
  <div class="radar document-view" style="background-color: white;padding-bottom: 10px;">
    <div class="jilu">
      <p class="title ft-bold">流水分析报告记录</p>
      <span style="text-align: center;">可查询微信和支付宝账单流水，<span>￥{{ price }}/次</span></span>
    </div>
    <!--使用说明-->
    <div style="border-top: 1px solid #DCDFE6;padding-top: 20px;">
      <div slot="header" class="yindao">
        <span style="font-size: 16px;">请使用以下任意一种方式引导客户导入账单</span>
      </div>
      <div class="ydfs">
        <div class="tp"><span>图片引导</span> &nbsp;让客户识别图片中的二维码完成账单导入, <el-button style="color: red;" type="text"
            @click="preview(1)">预览图片</el-button>，<el-link type="primary"
            @click="downloadImg(tp, '分析报告图片引导')">下载图片</el-link></div>
        <div class="wz"><span>文字引导</span> &nbsp;复制一段文字内容引导客户导入账单, <el-button style="color: red;" type="text"
            @click="preview(2)">预览文字内容</el-button>，<el-link v-clipboard="wz" v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopySuccess" type="primary">复制文字
          </el-link></div>
        <div class="sp"><span>视频引导</span> &nbsp;发送在线视频教程引导客户导入账单, <el-button style="color: red;" type="text"
            @click="preview(3)">预览视频</el-button>，<el-link v-clipboard="spCopy" v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopySuccess" type="primary">复制视屏链接
          </el-link>
        </div>
        <!--弹窗预览-->
        <el-dialog :width="action == 1 || action == 2 ? '375px' : '375px'" :visible.sync="dialogVisible"
          :title="action == 1 ? '图片预览' : action == 2 ? '预览文字内容' : '预览视频'">
          <img v-if="action == 1" :src="tp" alt="图片预览" style="display: block; max-width: 100%; margin: 0 auto;">
          <span v-if="action == 2" style="word-wrap: break-word; color: black; font-size: 16px;">{{ wz }}</span>
            <video v-if="action == 3" :src="sp" type="video/mp4"  width="100%" height="300px"
            controls="controls" loop="-1">
            <p>你的浏览器不支持video标签.</p>
          </video>
        </el-dialog>
      </div>
      <!-- <div style="text-align: center;color: gray; ">
        查询流水报告查询报告，成功将从账户扣除<span style="color: red;">{{ price }}</span>元
      </div> -->
      <div style="text-align: center;color: red; ">
        查询报告前，确保用户已成功收到电子证明发送通知，否则扣费了也接收不到账单数据
      </div>
      <el-divider></el-divider>
      <div style="text-align: center;margin-top: 10px; margin-bottom: 20px;">
        <div style="margin-bottom: 10px;" v-for="(it, index) in data" :key="index">
          <span style="font-weight: bold;">{{ it.subject }}，生成时间：{{ it.sendTime }}&nbsp;&nbsp;</span>
          <el-link type="primary" :href="it.reportURL" target="_blank">查看分析报告</el-link>--
          <el-link type="primary" :href="it.url" target="_blank">查看账单明细</el-link>
        </div>
        <el-button type="primary" @click="$emit('refresh-tab')">{{ data ? '刷新报告' : '查询报告' }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex"
import getReports from "@/mixins/getReports"

let FLAG = 'flow'
let email = '2d85@eeiai.cn'
// let video = process.env.VUE_APP_BASE_URL + 'video/guide-video.c306b2ba.mp4'
let video = 'https://onhetong.oss-cn-beijing.aliyuncs.com/guide-video.c306b2ba.mp4'

export default {
  mixins: [getReports],
  data() {
    return {
      dialogVisible: false,
      action: -1,
      guide: 'https://flow.eeiai.cn/bill?accountId=c92acc27dee340b8835dd02196025817',
      sp: video,
      tp: require('../../../assets/img/guide.jpg'),
      wz: '为更好给您提供服务，请您提供下【微信】【支付宝】 最近12个月账单，操作步骤如下: 提供下微信最近12个月账单(打开微信钱包->点进去右上角“账单”->右上角“常见问题”->“下载账单”->"用作证明材料"(一定要选这个)-“自定义” 选择最近近12个月发送到邮箱 zcc@eeiai.cn ) 打开支付宝点击右下角我的，点击账单，点击右上角“…”->“开具交易流水证明”->"用于证明材料"(一定要选这个)，申请交易类型选择全部交 易，时间范围选择最近12个月，点下一步，发送至' + email,
      spCopy: 'https://www.bilibili.com/video/BV1sS23YwECN/'
    }
  },
  computed: {
    ...mapState(`userReport/${FLAG}`, { date: "date" }),
    ...mapGetters(`userReport/${FLAG}`, ["data"]),
    ...mapState("userReport", ["uid", "price"])
  },
  created() {
    this.load(false, true)
  },
  methods: {
    ...mapActions(`userReport/${FLAG}`, ["getData"]),
    ...mapActions("userReport", ["checkPrice"]),
    load(refresh = false, preview = false) {
      this.getData({ refresh: refresh, preview }).then((res) => res && this.getReports())
    },
    onCopySuccess() {
      this.$notify({
        message: `已成功复制到粘贴板！`,
        type: 'success',
        offset: 400,
        duration: 1500
      })
    },
    preview(action) {
      this.dialogVisible = true
      this.action = action
    },
    downloadImg(url, fileName) {
      const x = new window.XMLHttpRequest();
      x.open('GET', url, true);
      x.responseType = 'blob';
      x.onload = () => {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    }
  }
}
</script>

<style lang="scss" scoped>
.radar {
  position: relative;
  padding: 0 10px 10px 10px;

  &-score {
    width: 65%;
    margin-left: 20px;

    &-item {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;

      &-name {
        width: 75px;
        margin-right: 20px;
      }
    }

    &-tips {
      width: 30%;
      text-align: left;

      h4 {
        margin-bottom: 30px;
      }
    }
  }

  &-search {
    padding: 0 20px;

    .search-sum {
      width: 30%;

      &-top {
        padding: 13px 20px;
        margin-bottom: 2px;

        .sum-num {
          margin-left: 26px;
          color: rgba(64, 158, 255, 1);
        }
      }

      &-bottom {
        padding: 13px 30px;

        &-item {
          .item-num {
            color: rgba(64, 158, 255, 1);
          }
        }
      }
    }

    &-num-block {
      padding-top: 28px;
    }
  }

  &-current {
    padding-left: 20px;

    &-bottom {
      margin-bottom: 10px;

      &-left {
        width: 30%;
        padding: 50px 20px;
        box-sizing: border-box;
        text-align: center;
        margin-right: 5px;
        vertical-align: middle;
      }

      .right-block {
        width: 24%;
        text-align: center;

        &-top {
          padding: 20px;
          margin-bottom: 5px;
        }

        &-bottom {
          padding: 10px;
        }
      }
    }
  }

  &-behavior {
    padding-left: 20px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}

.el-col-8 {
  font-size: 14px;
  line-height: 1.6rem;
  width: 33.3333333333%
}

.yindao {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.ydfs {
  margin: 0 auto;
  text-align: center;
  display: table;
  margin-bottom: 15px;
}

.ydfs span {
  display: inline-block;
  padding: 0 10px;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  padding: 5px 8px;
}

.tp, .wz, .sp{
  line-height: 20px;
  margin-bottom: 5px;
}
.tp span {
  background-color: #409eff;
}

.wz span {
  background-color: #67c23a;
}

.sp span {
  background-color: #f56c6c;
}

.copy {
  display: inline;
  line-height: 20px;
  color: #409EFF;
}

@media screen and (max-width: 767px) {
  .el-col-8 {
    width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .el-col-8 {
    width: 100%;
  }
}
</style>
