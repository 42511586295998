<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">全景档案记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <radar-block title="结果详情">
      <div class="radar-behavior">
        <el-table border :data="list1" style="width: 100%" header-row-class-name="light-head"
          :row-class-name="tableRowClassName">
          <el-table-column prop="member_count" label="逾期机构数(家)" align="center"></el-table-column>
          <el-table-column prop="order_count" label="逾期订单数(笔)" align="center"></el-table-column>
          <el-table-column prop="debt_amount" label="逾期总金额(元)" align="center"></el-table-column>
          <el-table-column prop="current_org_count" label="近1月共债机构数(家)" align="center"></el-table-column>
          <el-table-column prop="current_order_count" label="近1月共债订单数(笔)" align="center"></el-table-column>
          <el-table-column prop="current_order_amt" label="近1月共债订单已还款金额(元)" align="center"></el-table-column>
          <el-table-column prop="current_order_lend_amt" label="近1月共债订单金额(元)" align="center"></el-table-column>
        </el-table>
      </div>
    </radar-block>
    <radar-block title="逾期详情">
      <div class="radar-behavior">
        <el-table border :data="list2" style="width: 100%" header-row-class-name="light-head"
          :row-class-name="tableRowClassName">
          <el-table-column prop="endDay" label="逾期时间" align="center"></el-table-column>
          <el-table-column prop="billType" label="逾期账期数" align="center"></el-table-column>
          <el-table-column prop="endMoney" label="逾期金额(元)" align="center"></el-table-column>
          <el-table-column prop="endFlag" label="是否结清" align="center"></el-table-column>
        </el-table>
      </div>
    </radar-block>
    <radar-block title="历史共债">
      <div class="radar-behavior">
        <el-table border :data="list3" style="width: 100%" header-row-class-name="light-head"
          :row-class-name="tableRowClassName">
          <el-table-column prop="totaldebt_date" label="共债统计时间范围" align="center"></el-table-column>
          <el-table-column prop="totaldebt_org_count" label="共债机构数(家)" align="center"></el-table-column>
          <el-table-column prop="totaldebt_order_count" label="共债订单数(笔)" align="center"></el-table-column>
          <el-table-column prop="totaldebt_order_amt" label="共债订单已还款金额(元)" align="center"></el-table-column>
          <el-table-column prop="new_or_old" label="疑似借新还旧" align="center"></el-table-column>
          <el-table-column prop="totaldebt_order_lend_amt" label="共债订单金额(元)" align="center"></el-table-column>
        </el-table>
      </div>
    </radar-block>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from "vuex";
import radarBlock from "./radar-block";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
export default {
  mixins: [getReports],
  components: { DocumentGenerator, radarBlock },
  methods: {
    ...mapActions("userReport/archives", ["getArchives"]),
    load (refresh = false) {
      this.getArchives(refresh).then(res => res && this.getReports());
    }
  },
  computed: {
    ...mapGetters("userReport/archives", ["list1", "list2", "list3"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/archives", ["date"])
  },
  created () {
    this.load();
  }
};
</script>

<style lang="scss" scoped>
.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
    padding: 3px 5px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 14px;
    }
  }
}
</style>
