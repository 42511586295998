<template>
  <div class="probe">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">新版反欺诈记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <div class="newqz">
      <el-row>
        <el-col :span="10">
          <div class="grid-content">
            <div id="myChart"></div>
          </div>
        </el-col>
        <el-col :span="14">
          <div class="grid-content">
            <div class="result">
              <p style="font-size: 18px; color: red; margin-top: 30px;">匹配结果：778777分，{{ checkPass(data.RiskScore) }}</p>
              <p>743-900分：记好，未匹配到相关风险记录</p>
              <p>678-743分：良好，建议作为风控变量入模</p>
              <p>635-678分：一般，建议作为风控变量入模</p>
              <p>563-635分：逾期概率较高，建议拒绝</p>
              <p>300-563分：风险极高，建议拒绝</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import getReports from "@/mixins/getReports"
import DocumentGenerator from "@/components/viewBar/reports/document-generator"
import echarts from "@/lib/echarts"

export default {
  mixins: [getReports],
  components: {
    DocumentGenerator
  },
  computed: {
    ...mapGetters("userReport/antiFraud", ["data"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/antiFraud", ["date"])
  },
  methods: {
    ...mapActions("userReport/antiFraud", ["getData"]),
    load (refresh = false) {
      this.getData(refresh).then((res) => {
        this.$nextTick(() => {
          this.initCharts(this.$store.getters['userReport/antiFraud/data'].RiskScore)
        })
        return res && this.getReports()
      })
    },
    initCharts (value) {
      let myChart = echarts.init(document.getElementById("myChart"))
      myChart.setOption({
        series: [
          {
            type: 'gauge',
            axisLine: {
              lineStyle: {
                width: 30,
                color: [
                  [0.5, '#0de430'],
                  [0.6, '#67e0e3'],
                  [0.7, '#67e0e3'],
                  [0.8, '#d88f8f'],
                  [1, '#fd666d']
                ]
              }
            },
            pointer: {
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              distance: -30,
              length: 8,
              lineStyle: {
                color: '#fff',
                width: 2
              }
            },
            splitLine: {
              distance: -30,
              length: 30,
              lineStyle: {
                color: '#fff',
                width: 4
              }
            },
            axisLabel: {
              color: 'inherit',
              distance: 10,
              fontSize: 20
            },
            detail: {
              valueAnimation: true,
              formatter: '{value} km/h',
              color: 'inherit'
            },
            data: [
              {
                value: value
              }
            ]
          }
        ]
      })
    },
    checkPass (val) {
      return val <= 50 ? '建议通过' : val <= 60 ? '建议变量入模' : '建议拒绝'
    }
  },
  created () {
    this.load()
  }
};
</script>

<style lang="scss" scoped>
.el-card__body {
  padding: 5px !important;
}

.name {
  font-weight: bold;
  color: #000;
  font-size: 14px;
  white-space: normal;
  display: inline-block;
  width: max-content;
}

.cbody {
  background-color: #f8f8f8;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.tag-red {
  color: rgb(185, 12, 12);
  text-align: center;
  float: right;
  border: 1px solid #efa3a3;
  background-color: #f7e1e1;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.tag-green {
  color: rgb(103, 193, 57);
  text-align: center;
  float: right;
  border: 1px solid rgb(103, 193, 57);
  background-color: rgb(223, 234, 218);
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;
  height: 70vh;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 110px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}

.newqz {
  width: 65%;
  margin: 0 auto;
}

.grid-content {
  position: relative;
  height: 260px;
  margin: 0 auto;
  width: 100%;
  display: block;
}

.result {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translate3d(-50%, -50%, 0);
}

.result p {
  font-weight: bold;
  font-size: 14px;
  line-height: 2.2;
}

.w-6 {
  height: 260px;
  margin: 0 auto;
  display: block;
}

#myChart {
  width: 100%;
  height: 410px;
}
</style>