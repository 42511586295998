<template>
  <div class="probe document-view">
    <document-generator/>
    <div class="jilu">
      <p class="title ft-bold">探针-C记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span
        ><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <div class="fx-m-between fx-wrap">
      <num-block
        class="with-margin"
        v-for="(item, index) in probeList1"
        :key="index"
        width="32%"
        :title="item.name"
        :num="item.value"
        :unit="item.unit"
        :num-color="item.color"
      ></num-block>
    </div>
  </div>
</template>

<script>
import numBlock from "./num-block";

import { mapState, mapActions, mapGetters } from "vuex";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
export default {
  mixins: [getReports],
  components: {DocumentGenerator, numBlock },
  methods: {
    ...mapActions("userReport/behavior", ["getBehavior"]),
    load(refresh = false) {
      this.getBehavior(refresh).then(res => res && this.getReports());
    }
  },
  computed: {
    ...mapGetters("userReport/behavior", ["probeList1", "probeList2"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/behavior", ["date"])
  },
  created() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>
.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;
  .with-margin {
    margin-bottom: 10px;
  }
  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;
    .title {
      font-size: 24px;
      color: #000000;
    }
    .refreshBtn {
      background-color: #f56c6c;
    padding: 3px 5px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 14px;
    }
  }
}
</style>
