<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">逾期风险报告</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <el-row>
      <el-col :span="8">
        <div class="grid-content bg-purple">信⽤⻛险评分，0-1之间分数越⾼⽤户信⽤越低</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">履约⾦额综合指数，0-1之间指数越⼤⽤户逾期可能性越⾼</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">履约笔数综合指数，0-1之间指数越⼤⽤户逾期可能性越⾼</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-whilt">{{ data.xyp_cpl0081 }}</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-whilt">{{ data.xyp_cpl0082 }}</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-whilt over">{{ data.xyp_cpl0083 }}</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <el-tooltip class="item" effect="dark" :content="'得分(范围是[350,950],越⼤逾期率越低):' + data.xyp_model_score_high"
            placement="top-start">
            <span>⼩额⽹贷逾期预测概率</span>
          </el-tooltip>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <el-tooltip class="item" effect="dark" :content="'得分(范围是[350,950],越⼤逾期率越低):' + data.xyp_model_score_mid"
            placement="top-start">
            <span>⼩额分期逾期预测概率</span>
          </el-tooltip>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <el-tooltip class="item" effect="dark" :content="'得分(范围是[350,950],越⼤逾期率越低):' + data.xyp_model_score_low"
            placement="top-start">
            <span>中大额分期逾期预测概率</span>
          </el-tooltip>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-whilt">{{ calculate(data.xyp_model_score_high) }}</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-whilt">{{ calculate(data.xyp_model_score_mid) }}</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-whilt">{{ calculate(data.xyp_model_score_low) }}</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        <div class="grid-content bg-purple">贷款总机构数</div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple">贷款已结清机构数</div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple">当前是否存在逾期</div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple">当前逾期机构数</div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple">信⽤贷款时⻓</div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple-whilt">{{ data.xyp_cpl0001 }}</div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple-whilt">{{ data.xyp_cpl0002 }}</div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple-whilt" :style="overdueColor(data.xyp_cpl0044)">
          {{ overdueText(data.xyp_cpl0044) }}</div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple-whilt">{{ data.xyp_cpl0071 }}</div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 220, 450, 520], data.xyp_cpl0045, '小时') }}</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="grid-content title2 red shili">数据解释示例: [5，8），表示大于等于5，小于8；横线‘-’表示暂无数据</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple">消费⾦融类最后⼀次交易失败后还款次数</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple">⼩贷担保类最后⼀次交易失败后还款次数</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1, 2, 4], data.xyp_cpl0052, '次') }}</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1, 2, 4, 10], data.xyp_cpl0053, '次') }}</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple">最近⼀次还款成功距离当前天数</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple">当前逾期⾦额</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 5, 50, 160], data.xyp_cpl0068, '天') }}</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-whilt red">{{ parse([0, 1000, 2000, 3000, 5000, 7000, 11000],
          data.xyp_cpl0072, '元') }}</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="grid-content title2">最近一个月内是否存在逾期未结清</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple">消费⾦融类机构数（有场景的、分期）</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple">⽹络贷款类机构数（现⾦贷）</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1, 2, 4], data.xyp_cpl0007, '家') }}</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1, 5, 11], data.xyp_cpl0008, '家') }}</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="grid-content title2">最近半年贷款机构数</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">1天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">7天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">14天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">21天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">30天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">90天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">180天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1, 3], data.xyp_cpl0070, '家') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1, 9], data.xyp_cpl0009, '家') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1, 3, 10], data.xyp_cpl0010, '家') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 3, 6, 11], data.xyp_cpl0063, '家') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1, 4, 11], data.xyp_cpl0011, '家') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 5, 10, 17], data.xyp_cpl0012, '家') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 4, 7, 13], data.xyp_cpl0013, '家') }}</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple">历史贷款机构成功还款笔数</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple">历史贷款机构交易失败笔数</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 2, 10, 34, 60], data.xyp_cpl0014, '笔') }}</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 3, 5, 10], data.xyp_cpl0015, '笔') }}</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="grid-content title2">最近半年成功还款笔数</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">1天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">7天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">14天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">21天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">30天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">90天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">180天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 3], data.xyp_cpl0017, '笔') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 3, 13], data.xyp_cpl0019, '笔') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 5, 21], data.xyp_cpl0021, '笔') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 5, 27], data.xyp_cpl0064, '笔') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 7, 34], data.xyp_cpl0023, '笔') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 2, 31], data.xyp_cpl0025, '笔') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 3, 27, 50], data.xyp_cpl0027, '笔') }}</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="grid-content title2">最近半年交易失败笔数</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">1天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">7天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">14天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">21天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">30天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">90天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">180天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 3, 5], data.xyp_cpl0016, '笔') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 3, 5, 7], data.xyp_cpl0018, '笔') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 3, 5, 15], data.xyp_cpl0020, '笔') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 3, 5, 20], data.xyp_cpl0065, '笔') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 3, 5, 34], data.xyp_cpl0022, '笔') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 6, 55, 56], data.xyp_cpl0024, '笔') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 3, 25, 30, 70], data.xyp_cpl0026, '笔') }}</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="grid-content title2">最近一个月内是否发⽣过逾期</div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">1天</div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">7天</div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">14天</div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">30天</div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple-whilt" :style="overdueColor(data.xyp_cpl0028)">
          {{ overdueText(data.xyp_cpl0028) }}</div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple-whilt" :style="overdueColor(data.xyp_cpl0029)">
          {{ overdueText(data.xyp_cpl0029) }}</div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple-whilt" :style="overdueColor(data.xyp_cpl0030)">
          {{ overdueText(data.xyp_cpl0030) }}</div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple-whilt" :style="overdueColor(data.xyp_cpl0031)">
          {{ overdueText(data.xyp_cpl0031) }}</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="grid-content title2">最近半年还款成功总金额</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">1天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">7天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">14天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">21天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">30天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">90天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">180天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1500, 5000], data.xyp_cpl0033, '元') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 2000, 17000], data.xyp_cpl0035, '元') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 9000, 31000], data.xyp_cpl0037, '元') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1000, 34000], data.xyp_cpl0067, '元') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 10000, 36000], data.xyp_cpl0039, '元') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 13000, 49000], data.xyp_cpl0041, '元') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 2000, 58000], data.xyp_cpl0043, '元') }}</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="grid-content title2">最近半年交易失败总金额</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">1天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">7天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">14天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">21天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">30天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">90天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">180天</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1500, 2500, 5000], data.xyp_cpl0032, '元') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 2000, 10000, 17000, 26000], data.xyp_cpl0034, '元') }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1000, 4000, 9000, 30000], data.xyp_cpl0036, '元') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 1000, 2000, 30000, 50000], data.xyp_cpl0066, '元') }}
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 6000, 10000, 50000], data.xyp_cpl0038, '元') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 10000, 50000, 80000], data.xyp_cpl0040, '元') }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 2000, 30000, 60000, 90000], data.xyp_cpl0042, '元') }}
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <div class="grid-content bg-purple">近5次还款中成功还款总⾦额/近5次还款总⾦额</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">近5次还款中还款成功笔数/近5次还款总笔数</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">近5次还款中还款成功总⾦额</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-whilt">{{ data.xyp_cpl0073 }}</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-whilt">{{ data.xyp_cpl0074 }}</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 4000, 8000], data.xyp_cpl0077, '元') }}</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <div class="grid-content bg-purple">近20次还款中⼩贷担保类还款成功笔数/近20次还款中⼩贷担保类还款笔数</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">近20次还款中还款成功笔数</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple">近20次还款中还款成功总⾦额</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-whilt">{{ data.xyp_cpl0075 }}</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 2, 11, 13], data.xyp_cpl0076, '笔') }}</div>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-whilt">{{ parse([0, 2000, 25000], data.xyp_cpl0078, '元') }}</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <div class="grid-content bg-purple">近90天内还款成功总⾦额/近90天还款总⾦额</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple">近90天内还款成功笔数/近90天内还款总笔数</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-whilt">{{ data.xyp_cpl0079 }}</div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple-whilt">{{ data.xyp_cpl0080 }}</div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import getReports from "@/mixins/getReports"
import DocumentGenerator from "@/components/viewBar/reports/document-generator"
import { parseText } from "@/utils/BizConvert.js"

export default {
  mixins: [getReports],
  components: {
    DocumentGenerator
  },
  methods: {
    ...mapActions("userReport/dk", ["getData"]),
    load (refresh = false) {
      this.getData(refresh).then((res) => res && this.getReports())
    },
    calculate (val) {
      if (val == -1) {
        return '数据不详，无法预测'
      }
      return ((950 - val) / 600 * 100).toFixed(2) + '%'
    },
    parse (arr, val, unit) {
      return parseText(arr, val, unit)
    },
    overdueColor (val) {
      return val == 1 ? 'color:red' : 'color:green'
    },
    overdueText (val) {
      return val == 1 ? '逾期' : '未逾期'
    }
  },
  computed: {
    ...mapGetters("userReport/dk", ["data"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/dk", ["date"])
  },
  created () {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.el-card__body {
  padding: 5px !important;
}

.name {
  font-weight: bold;
  color: #000;
  font-size: 14px;
  white-space: normal;
  display: inline-block;
  width: max-content;
}

.cbody {
  background-color: #f8f8f8;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.tag-red {
  color: rgb(185, 12, 12);
  text-align: center;
  float: right;
  border: 1px solid #efa3a3;
  background-color: #f7e1e1;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.tag-green {
  color: rgb(103, 193, 57);
  text-align: center;
  float: right;
  border: 1px solid rgb(103, 193, 57);
  background-color: rgb(223, 234, 218);
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}

.el-row {
  margin-bottom: 20px;
  font-size: 14px;
  color: #000;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  // border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #ecf2f9;
  border: 1px solid #e9ebf1;
  font-weight: bold;
  font-size: 13px !important;
}

.bg-purple-whilt {
  background: #fff;
  border: 1px solid #e9ebf1;
  line-height: 50px !important;
  min-height: 50px !important;
}

.red {
  color: red;
}

.grenn {
  color: green;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  // border-radius: 4px;
  min-height: 42px;
  line-height: 42px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.el-col-3 {
  width: 14.2857% !important;
}

.el-col-5 {
  width: 20% !important;
}

.title2 {
  display: block;
  width: 100%;
  text-align: left;
  line-height: 50px;
  font-size: 15px;
  font-weight: bold;
}
.shili{
  text-align: center;
  font-weight: 400;
  background-color: #527db8;
  color: #fff;
}
</style>