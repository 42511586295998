<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">探查综合记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <radar-block title="申请行为详情">
      <div class="fx-m-between fx-wrap">
        <num-block class="with-margin" v-for="(item, index) in apply" :key="index" width="32%" :title="item.label"
          :num="item.val"></num-block>
      </div>
    </radar-block>

    <radar-block title="信用详情">
      <div class="fx-m-between fx-wrap">
        <num-block class="with-margin" v-for="(item, index) in current" :key="index" width="32%" :title="item.label"
          :num="item.val"></num-block>
      </div>
    </radar-block>
    
    <radar-block title="放款还款详情">

      <el-table border :data="behavior.base" style="width: 100%" header-row-class-name="light-head"
        :row-class-name="tableRowClassName">
        <el-table-column prop="val1" label="贷款行为分" align="center"></el-table-column>
        <el-table-column prop="val2" label="正常还款订单数占贷款总订单数比例" align="center"></el-table-column>
        <el-table-column prop="val3" label="最近一次履约距今天数" align="center"></el-table-column>
        <el-table-column prop="val4" label="贷款行为置信度" align="center"></el-table-column>
        <el-table-column prop="val5" label="贷款已结清订单数" align="center"></el-table-column>
        <el-table-column prop="val6" label="信用贷款时长" align="center"></el-table-column>
        <el-table-column prop="val7" label="最近一次贷款放款时间" align="center"></el-table-column>
      </el-table>

      <el-table border :data="behavior.month" style="width: 100%;margin-top: 20px;" header-row-class-name="light-head"
        :row-class-name="tableRowClassName">
        <el-table-column prop="name" label="名称" align="center"></el-table-column>
        <el-table-column prop="val1" label="近1个月" align="center"></el-table-column>
        <el-table-column prop="val3" label="近3个月" align="center"></el-table-column>
        <el-table-column prop="val6" label="近6个月" align="center"></el-table-column>
        <el-table-column prop="val12" label="近12个月" align="center"></el-table-column>
        <el-table-column prop="val24" label="近24个月" align="center"></el-table-column>
      </el-table>

    </radar-block>
    
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import getReports from "@/mixins/getReports"
import DocumentGenerator from "@/components/viewBar/reports/document-generator"
import radarBlock from "./radar-block"
import numBlock from "./num-block"

export default {
  mixins: [getReports],
  components: { DocumentGenerator, radarBlock, numBlock },
  methods: {
    ...mapActions("userReport/explored", ["getExplored"]),
    load (refresh = false) {
      this.getExplored(refresh).then((res) => res && this.getReports());
    },
  },
  computed: {
    ...mapGetters("userReport/explored", ["apply", "behavior", "current"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/explored", ["date"]),
  },
  created () {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col-6 {
  width: 20% !important;
}

.el-col {
  border-radius: 0px;
}

.bg-purple-dark {
  background: #F3F8FE;
  border-bottom: 1px solid #e8eff6;
}

.bg-purple {
  background: #f7f3f0;
  border-bottom: 1px solid #ede8e4;
}

.bg-purple-light {
  background: #d3dce6;
  border-bottom: 1px solid #c6cfd9;
}

.bg-purple-light2 {
  background: #dbe6f3;
  border-bottom: 1px solid #d3dce6;
}

.bg-jihei {
  background: #fa4947;
}

.bg-gaowei {
  background: #ce760f;
}

.bg-guanzhu {
  background: #227cd7;
}

.bg-huidu {
  background: #22c3bc;
}

.bg-weimz {
  background: #489f12;
}

.grid-content {
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  color: #000;
  font-size: 14px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}
</style>
