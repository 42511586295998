<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">信用综合报告</p>
      <p>
        <span>上次生成时间：{{ date }}</span
        ><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <div v-if='data'>
      <el-image :src="data"></el-image>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import getReports from "@/mixins/getReports"
import DocumentGenerator from "@/components/viewBar/reports/document-generator"

export default {
  mixins: [getReports],
  components: { DocumentGenerator },
  methods: {
    ...mapActions("userReport/lightning", ["getLightning"]),
    load(refresh = false) {
      this.getLightning(refresh).then((res) => res && this.getReports());
    },
  },
  computed: {
    ...mapGetters("userReport/lightning", ["data"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/lightning", ["date"]),
  },
  created() {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 0;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-image {
  position: relative;
  top: -180px;
  z-index: 0;
}
.el-col-6 {
  width: 20% !important;
}
.el-col {
  border-radius: 0px;
}
.bg-purple-dark {
  background: #F3F8FE;
  border-bottom: 1px solid #e8eff6;
}
.bg-purple {
  background: #f7f3f0;
  border-bottom: 1px solid #ede8e4;
}
.bg-purple-light {
  background: #d3dce6;
  border-bottom: 1px solid #c6cfd9;
}
.bg-purple-light2 {
  background: #dbe6f3;
  border-bottom: 1px solid #d3dce6;
}
.bg-jihei {
  background: #fa4947;
}
.bg-gaowei {
  background: #ce760f;
}
.bg-guanzhu {
  background: #227cd7;
}
.bg-huidu {
  background: #22c3bc;
}
.bg-weimz {
  background: #489f12;
}
.grid-content {
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  color: #000;
  font-size: 14px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;
    position: relative;
    z-index: 999;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
    padding: 3px 5px;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 10px;
    font-size: 14px;
    }
  }
}
</style>
