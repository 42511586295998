/**
 * 将范围内的数值转换成对应的文字
 * @param {*} arr 数据值的集合
 * @param {*} val 当前数据值（arr的角标）
 * @param {*} unit 单位
 * @returns
 * [0, 220, 450, 520]
 */
export function parseText(arr, val, unit) {
  let text = "-"
  if (val) {
    if (val == 0) {
      text = 0
    } else {
      let index = val - 1
      if (index == 0) {
        text = `≤${arr[index + 1]}${unit}`;
      } else if (0 < index && index < arr.length - 1) {
        // text = `大于或者等于${arr[index]},小于${arr[index + 1]}${unit}`
        text = `[ ${arr[index]}，${arr[index + 1]}${unit} )`
      } else if (index == arr.length - 1) {
        // text = `大于或者等于${arr[index]}${unit}`
        text = `≥${arr[index]}${unit}`
      }
    }
  }
  return text
}
