<template>
    <div>
      <el-dialog
        title="提示"
        custom-class="refuseDialog"
        :visible.sync="isShowDialog"
        :width="width"
        top="20vh"
        :show-close="true"
        :modal="true"
        :close-on-click-modal="false"
        @open="openDialog"
        append-to-body
      >
        <p style="padding:0px 0px 10px 10px;">是否确认将用户{{username}}{{status ? '审核拒绝' : '加入黑名单'}}？</p>
        <el-form ref="form" label-width="80px" :rules="rules" :model="form" hide-required-asterisk>
          <el-form-item label="原因:" prop="reason">
            <el-select v-model="form.reason" placeholder="请选择"  style="width:100%" @change="selectChange">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="其他原因:" prop="otherReason" v-if="this.form.reason == 'other'">
            <el-input v-model="form.otherReason" maxlength="30" show-word-limit></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="cancel" size="small">取 消</el-button>
          <el-button type="primary" @click="confirm" size="small">确 定</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import service from '../api'
  export default {
    name: "refuse-dialog",
    props:{
      isShowDialog: {
        type: Boolean,
        default: false
      },
      username: {
        type: String,
        default: ""
      },
      status:{
        default: ""
      }
    },
    data(){
      return{
        width: '25%',
        form:{
          reason:'',
          otherReason:''
        },
        rules:{
          reason:[{required:true, message:'请选择原因', trigger:'change'}],
          otherReason:[
            {required: true, message:'请选择原因', trigger:'change'},
            // {pattern: /^((?!,|，|\|).)*$/ , message:'不可输入特殊字符', trigger:'blur'}
          ]
        },
        options:[],
      }
    },
    computed:{
      ...mapState('userReport', ["blackTag"]),
      ...mapState('userReport/verify', ['friend']),
    },
    methods:{
      openDialog(){
        this.getOptions();
        if(document.documentElement.clientWidth < 480){
          this.width = '80%'
        }
      },
      getOptions(){
        service.getRefuseOptions().then(res => {
          let list = [];
          res.sys_reject_reason.split(',').map(item => {
            list.push({value: item, label: item})
          })
          list.push({value: 'other', label:'其他'})
          this.options = list;
          if(this.friend.reason){
            this.init(res.sys_reject_reason);
          }else{
            this.form.reason = '';
            this.form.otherReason = '';
          }
        } )
      },
      init(arr){
        if(arr.indexOf(this.friend.reason) > -1){
          this.form.reason = this.friend.reason;
          this.form.otherReason = '';
        }else{
          this.form.reason = 'other';
          this.form.otherReason = this.friend.reason;
        }
      },
      confirm(){
        let check = false;
        this.$refs.form.validate(res => check = res)
        if(!check) return;
        let str = '';
        if(this.form.reason == 'other'){
          str = this.form.otherReason;
        }else{
          str = this.form.reason;
        }
        this.$emit('refuseConfirm', str)
        this.$refs.form.resetFields();
      },
      cancel(){
        this.$refs.form.resetFields();
        this.$emit('refuseCancel')
      },
      selectChange(value){
        this.form.otherReason = '';
      }
    },
  }
</script>

<style lang="scss">
.refuseDialog{
  .el-dialog__body{
    padding:10px 20px 0 20px;
  }
}
</style>
