<template>
  <div class="view-head ft-bold c-dkGrey">
    <div class="info">
      <div class="info-head">
        <div class="title ft-sz-15 fx-v-center">
          <img class="title-icon" src="@/assets/userManage/avatar.png" />
          <span>UID: {{ userInfo.userId || '' }}
            <span class="blackTags" v-show="showBlack">
              <el-tag size="mini" type="info" effect="plain" v-for="(item, index) in blackTag"
                :key="index">{{ item }}</el-tag>
            </span><noun-help v-if="blackTag.length" content="当前用户在系统中被关联商户选择的全部标签" />
          </span>
        </div>
      </div>
      <div class="info-body">
        <div class="ft-sz-13 ft-bold fx-wrap">
          <div class="info-body-item">姓名: {{ userInfo.name }}</div>
          <div class="info-body-item">年龄：{{ age }} ({{ gender }})</div>
          <div class="info-body-item">手机号: {{ user.mobile }}</div>
          <div class="info-body-item">身份证号：{{ userInfo.idcard }}</div>
          <div class="info-body-item blackList">共享黑名单<noun-help content="当前用户是否被系统中其它商户拉入黑名单" />：<span
              :class="isBlack ? 'c-red' : 'c-green'">{{ isBlack ? '是' : '否' }}</span></div>
          <div class="info-body-item">查询时间：{{ userInfo.createdAt }}</div>
        </div>
      </div>
    </div>
    <!--    审核拒绝， 加入黑名单弹框-->
    <refuse-dialog :isShowDialog="showReFuseDialog" :username="name" :status="dialogStatus" class="refuseDialog"
      @refuseConfirm="refuseConfirm" @refuseCancel="refuseCancel">
    </refuse-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { examUser } from '../reportBtn'
import refuseDialog from "@/components/viewBar/reports/refuse-dialog"
import nounHelp from "@/components/viewBar/reports/noun-help"
import service from "@/api/common.js"

export default {
  components: { refuseDialog, nounHelp },
  computed: {
    ...mapState('userReport/verify', ['user', 'userInfo', 'firstLogin', 'mobiles', 'riskInfo']),
    ...mapState('userReport', ['auditStatus', 'memo', "flId", "isBlack", "loading", "blackTag", "showBlack"]),
    ...mapGetters('userReport/verify', ['gender', 'age']),
    ...mapGetters('userReport', ['chartLists'])
  },
  props: {
    showBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      //按钮值
      exam: {},
      isAdmin: false,
      //只显示备注
      isOnlyMemo: false,
      showDialog: false,
      showReFuseDialog: false,
      name: '',
      dialogStatus: ''
    }
  },
  created() {
    switch (this.$route.path) {
      case "/allUsers":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      case "/users":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      case "/passList":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      case "/refuseList":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      case "/blacklist":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      case "/promote/setting":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      case "/risk/detail":
        this.isOnlyMemo = false;
        this.exam = examUser;
        break;
      default:
        this.isOnlyMemo = true;
        this.exam = {};
        break;
    }
    this.isAdmin = process.env.VUE_APP_ADMIN && process.env.VUE_APP_ADMIN === "true"
  },
  methods: {
    ...mapMutations("userReport", ["setAuditStatus", "setMemo"]),
    ...mapActions("userReport/verify", ["getVerify"]),
    //加入黑名单
    addBlack() {
      this.name = this.userInfo.name;
      this.dialogStatus = 0;
      this.showReFuseDialog = true;
    },
    //移出黑名单
    delBlack() {
      this.$alert(
        `是否确认将用户${this.userInfo.name}移出黑名单?`,
        "提示",
        { showCancelButton: true }
      ).then(() => {
        service.del({ userId: this.userInfo.userId, status: 0 }).then(res => {
          this.showDialog = false;
          this.setAuditStatus(0);
          this.getVerify();
          this.$notify({ type: 'success', message: '操作成功！' });
        })
      }).catch(() => { })
    },
    //审核拒绝，加入黑名单
    refuseConfirm(reason) {
      let status = null;
      if (this.dialogStatus) {
        status = 2;
      } else {
        status = -1;
      }
      service.add('api/blacklist',{ name: this.userInfo.username, idCard:this.userInfo.idcard,mobile:this.userInfo.mobile, reason: reason }).then(res => {
        this.showReFuseDialog = false;
        this.setAuditStatus(status);
        this.getVerify();
        this.$notify({ type: 'success', message: '操作成功！' });
      })
    },
    refuseCancel() {
      this.showReFuseDialog = false;
    }
  },
  watch: {
    isBlack(newValue, oldValue) {
      this.isBlack = newValue;
    },
    blackTag(newValue, oldValue) {
      this.blackTag = newValue;
    },
  }
};
</script>

<style lang="scss" scoped>
.view-head {
  text-align: left;

  .title {
    margin-bottom: 15px;

    img {
      width: 24px;
      height: auto;
      margin-right: 13px;
    }
  }

  .info {
    padding: 20px 20px 8px;
    background-color: #fff;

    .blackTags {
      margin-left: 10px;
      margin-right: 2px;

      .el-tag {
        color: #FFFFFF;
        border-color: #fe0101;
        background: #fe0101;
      }

      .el-tag+.el-tag {
        margin-left: 10px;
      }
    }

    &-body {
      &-item {
        width: 33%;
        margin-bottom: 14px;
        white-space: nowrap;
        color: rgba(76, 76, 76, 1);
      }

      .blackList {
        color: #409EFF;
      }

      .riskInfo {
        border-top: 2px solid #f7f8fc;
        padding-top: 14px;
      }
    }

    .info-btns {
      position: fixed;
      top: 100px;
      right: 50px;
      z-index: 9999;
      width: 50px;
      clear: both;

      .info-btn {
        .btn {
          float: left;

          &:nth-child(1) {
            margin-bottom: 10px;
          }
        }

        .btn+.btn {
          margin: 0;
          margin-bottom: 10px;
        }
      }

      .el-button--primary {
        &:hover {
          background-color: #1E6FFF;
          border-color: #1E6FFF;
        }
      }

      .el-button--mini {
        padding: 13px 8px;
      }
    }
  }

  .fontNonmal {
    font-weight: 500;
  }

  // .risk {
  //   margin-top: 15px;
  //   &-head {
  //     padding: 18px 20px 0 20px;
  //     background-color: #fff;
  //     margin-bottom: 2px;
  //   }
  //   &-body {
  //     padding: 28px 80px;
  //     background-color: #fff;
  //   }
  // }
}

@media screen and (max-width: 480px) {

  .view-head {
    .info {
      padding: 1rem;

      .info-btns {
        top: auto;
        right: 1rem;
      }
    }

    .info-body-item {
      width: 100%;
      font-size: 14px;
    }

    .title {}
  }
}
</style>
