<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">信贷保镖记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <div v-if="data">
      <radar-block title="贷前反欺诈">
        <el-table border :data="data1" style="width: 100%" header-row-class-name="light-head"
          :row-class-name="tableRowClassName">
          <el-table-column prop="final_score" label="风险分数" align="center"></el-table-column>
          <el-table-column prop="final_decision" label="决策结果" align="center"></el-table-column>
        </el-table>
      </radar-block>
      <radar-block title="风险项目" style="margin-top: 20px;">

        <template>
          <el-table :data="data2" border header-row-class-name="light-head" style="width: 100%">
            <!--风险详情-->
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-form label-position="left" inline class="demo-table-expand">
                  <div v-if="props.row.risk_detail && props.row.risk_detail.length > 0">
                    <div v-for="(riskDetail, detailIndex) in props.row.risk_detail" :key="detailIndex">
                      <!--风险群体风险详情表-->
                      <radar-block v-if="riskDetail.type === 'suspected_team'" title="风险群体风险详情表">
                        <div v-for="(team, teamIndex) in riskDetail.suspect_team_detail_list" :key="teamIndex">
                          <el-form-item label="匹配字段名称"><span>{{ team.dim_type }}</span></el-form-item>
                          <el-form-item label="匹配字段值"><span>{{ team.dim_value }}</span></el-form-item>
                          <el-form-item label="疑似风险群体编号"><span>{{ team.group_id}}</span></el-form-item>
                          <el-form-item label="疑似风险群体成员数"><span>{{team.total_cnt }}</span></el-form-item>
                          <el-form-item label="风险名单成员数"><span>{{ team.black_cnt}}</span></el-form-item>
                          <el-form-item label="关注名单成员数"><span>{{ team.grey_cnt }}</span></el-form-item>
                          <el-form-item label="成员分布"><span>{{ team.node_dist }}</span></el-form-item>
                          <el-form-item label="风险名单分布"><span>{{ team.fraud_dist}}</span></el-form-item>
                          <el-form-item label="风险名单占比"><span>{{ team.black_rat }}</span></el-form-item>
                          <el-form-item label="关注名单占比"><span>{{ team.grey_rat }}</span></el-form-item>
                          <el-form-item label="一度关联节点个数"><span>{{ team.degree }}</span></el-form-item>
                          <el-form-item label="二度关联节点个数"><span>{{ team.total_cnt_two }}</span></el-form-item>
                          <el-form-item label="一度风险名单个数"><span>{{ team.black_cnt_one }}</span></el-form-item>
                          <el-form-item label="一度风险名单分布"><span>{{ team.fraud_dist_one }}</span></el-form-item>
                          <el-form-item label="二度风险名单个数："><span>{{ team.black_cnt_two }}</span></el-form-item>
                          <el-form-item label="二度风险名单分布"><span>{{ team.fraud_dist_two }}</span></el-form-item>
                          <el-form-item label="风险节点距离"><span>{{ team.black_dst }}</span></el-form-item>
                          <el-form-item label="核心节点距离"><span>{{ team.core_dst }}</span></el-form-item>
                          <el-form-item label="关联风险分"><span>{{ team.node_score }}</span></el-form-item>
                        </div>
                      </radar-block>
                      <!--关注名单风险详情表-->
                      <radar-block v-if="riskDetail.type === 'grey_list'" title="风险群体风险详情表">
                        <el-form-item label="规则描述："><span>{{ riskDetail.description }}</span></el-form-item>
                        <el-form-item label="规则命中："><span>{{ riskDetail.hit_type_display_name }}</span></el-form-item>
                      </radar-block>
                      <!--模糊证据库风险详情表-->
                      <radar-block v-if="riskDetail.type === 'fuzzy_black_list'" title="模糊证据库风险详情表">
                        <el-form-item label="规则描述："><span>{{ riskDetail.description }}</span></el-form-item>
                        <el-form-item label="规则命中："><span>{{ riskDetail.hit_type_display_name }}</span></el-form-item>
                        <div v-for="(fuzzy, fuzzyIndex) in riskDetail.fuzzy_list_details" :key="fuzzyIndex">
                          <el-form-item label="逾期金额区间："><span>{{ fuzzy.overdue_amount_range }}</span></el-form-item>
                          <el-form-item label="逾期笔数："><span>{{ fuzzy.overdue_count }}</span></el-form-item>
                          <el-form-item label="逾期时间区间："><span>{{ fuzzy.overdue_day_range }}</span></el-form-item>
                          <el-form-item label="逾期入库时间："><span>{{ fuzzy.overdue_time }}</span></el-form-item>
                        </div>
                      </radar-block>
                      <!--频度风险详情表-->
                      <radar-block v-if="riskDetail.type === 'frequency_detail'" title="模糊证据库风险详情表">
                        <div v-for="(frequency, frequencyIndex) in riskDetail.frequency_detail_list"
                          :key="frequencyIndex">
                          <el-form-item label="规则描述："><span>{{ frequency.note }}</span></el-form-item>
                          <el-form-item label="规则命中："><span>{{ frequency.detail }}</span></el-form-item>
                        </div>
                      </radar-block>
                      <!--跨事件频度风险详情表-->
                      <radar-block v-if="riskDetail.type === 'cross_frequency_detail'" title="跨事件频度风险详情表">
                        <div v-for="(cross, crossIndex) in riskDetail.cross_frequency_detail_list" :key="crossIndex">
                          <el-form-item label="规则描述："><span>{{ cross.note }}</span></el-form-item>
                          <el-form-item label="规则命中："><span>{{ cross.detail }}</span></el-form-item>
                        </div>
                      </radar-block>
                      <!--跨事件字段风险详情表-->
                      <radar-block v-if="riskDetail.type === 'cross_event_detail'" title="跨事件字段风险详情表">
                        <div v-for="(event, eventIndex) in riskDetail.cross_event_detail_list" :key="eventIndex">
                          <el-form-item label="规则描述："><span>{{ event.note }}</span></el-form-item>
                          <el-form-item label="规则命中："><span>{{ event.detail }}</span></el-form-item>
                        </div>
                      </radar-block>
                      <!--自定义列表风险详情表-->
                      <radar-block v-if="riskDetail.type === 'custom_list'" title="自定义列表风险详情表">
                        <el-form-item label="规则描述："><span>{{ riskDetail.description }}</span></el-form-item>
                      </radar-block>
                      <!--多平台风险详情表-->
                      <radar-block v-if="riskDetail.type === 'platform_detail'" title="多平台风险详情表">
                        <el-form-item label="规则描述："><span>{{ riskDetail.description }}</span></el-form-item>
                        <el-form-item label="平台个数："><span>{{ riskDetail.platform_count }}</span></el-form-item>
                      </radar-block>
                      <!--风险名单风险详情表-->
                      <radar-block v-if="riskDetail.type === 'black_list'" title="风险名单风险详情表">
                        <el-form-item label="规则描述："><span>{{ event.description }}</span></el-form-item>
                        <el-form-item label="规则命中："><span>{{ event.hit_type_display_name }}</span></el-form-item>
                      </radar-block>
                      <!--设备状态异常风险详情表-->
                      <radar-block v-if="riskDetail.type === 'device_status_abnormal'" title="设备状态异常风险详情表">
                        <div v-for="(device, deviceIndex) in riskDetail.abnormal_tags" :key="deviceIndex">
                          <el-form-item label="疑似刷机："><span>{{ device.SUSPECTED_OF_WIPING_PHONE }}</span></el-form-item>
                          <el-form-item label="开机时间过短："><span>{{ device.SHORT_UPTIME }}</span></el-form-item>
                          <el-form-item label="本机时间异常："><span>{{ device.ABNORMAL_TIME }}</span></el-form-item>
                          <el-form-item label="设备首次出现："><span>{{ device.DEVICE_FIRST_SEEN }}</span></el-form-item>
                          <el-form-item label="运营商异常："><span>{{ device.ABNORMAL_CARRIER_INFO }}</span></el-form-item>
                          <el-form-item label="网络信息异常："><span>{{ device.ABNORMAL_NETWORK_CONNECTION }}</span></el-form-item>
                          <el-form-item label="客户端ID异常："><span>{{ device.ILLEGAL_CLIENTID }}</span></el-form-item>
                          <el-form-item label="设备为模拟器："><span>{{ device.ANDROID_EMULATOR }}</span></el-form-item>
                          <el-form-item label="多开："><span>{{ device.MULTIPLE_RUNNING }}</span></el-form-item>
                          <el-form-item label="检测到调试器："><span>{{ device.DEBUGGER_DETECTED }}</span></el-form-item>
                          <el-form-item label="检测到改机工具："><span>{{ device.HOOK_TOOL_DETECTED }}</span></el-form-item>
                          <el-form-item label="设备参数被篡改："><span>{{ device.DEVICE_INFO_TAMPERED }}</span></el-form-item>
                          <el-form-item label="疑似伪造基站定位："><span>{{ device.SUSPECTED_OF_FAKING_LOCATION }}</span></el-form-item>
                          <el-form-item label="疑似伪造无线网络信："><span>{{ device.SUSPECTED_OF_FAKING_WIFI }}</span></el-form-item>
                        </div>
                      </radar-block>
                      <!--设备指纹异常风险详情表-->
                      <radar-block v-if="riskDetail.type === 'fp_exception'" title="设备指纹异常风险详情表">
                        <el-form-item label="异常代码："><span>{{ riskDetail.code }}</span></el-form-item>
                        <el-form-item label="代码名称："><span>{{ riskDetail.code_display_name }}</span></el-form-item>
                      </radar-block>
                      <!--函数工具箱风险详情表-->
                      <radar-block v-if="riskDetail.type === 'function_kit'" title="函数工具箱风险详情表">
                        <el-form-item label="计算结果："><span>{{ riskDetail.result }}</span></el-form-item>
                      </radar-block>
                      <!--信贷名单风险详情表-->
                      <radar-block v-if="riskDetail.type === 'creditList_index_detail'" title="信贷名单风险详情表">
                        <el-form-item label="匹配字段："><span>{{ riskDetail.dim_type }}</span></el-form-item>
                        <el-form-item label="计算结果："><span>{{ riskDetail.result }}</span></el-form-item>
                        <div v-for="(hits, hitsIndex) in riskDetail.hits" :key="hitsIndex">
                          <el-form-item label="风险时间："><span>{{ hits.evidence_time }}</span></el-form-item>
                          <el-form-item label="逾期金额："><span>{{ hits.overdue_amount }}</span></el-form-item>
                          <el-form-item label="逾期期数："><span>{{ hits.overdue_counts }}</span></el-form-item>
                          <el-form-item label="逾期天数："><span>{{ hits.overdue_days }}</span></el-form-item>
                          <el-form-item label="来源行业："><span>{{ hits.industry }}</span></el-form-item>
                          <el-form-item label="主体类型："><span>{{ hits.subject_type }}</span></el-form-item>
                          <el-form-item label="角色："><span>{{ hits.role }}</span></el-form-item>
                        </div>
                      </radar-block>
                      <!--四则运算风险详情表-->
                      <radar-block v-if="riskDetail.type === 'four_calculate'" title="四则运算风险详情表">
                        <el-form-item label="计算结果："><span>{{ riskDetail.result }}</span></el-form-item>
                      </radar-block>
                      <!--关联风险分详情表-->
                      <radar-block v-if="riskDetail.type === 'assoc_risk_score'" title="关联风险分详情表">
                        <el-form-item label="匹配字段："><span>{{ riskDetail.dim_type }}</span></el-form-item>
                        <el-form-item label="综合关联风险分："><span>{{ riskDetail.node_score }}</span></el-form-item>
                        <el-form-item label="计算结果："><span>{{ riskDetail.dim_value }}</span></el-form-item>
                        <div v-for="(assoc, assocIndex) in riskDetail.assoc_risk_score_detail_list" :key="assocIndex">
                          <el-form-item label="匹配字段："><span>{{ assoc.dim_type }}</span></el-form-item>
                          <el-form-item label="计算结果："><span>{{ assoc.dim_value }}</span></el-form-item>
                        </div>
                      </radar-block>
                      <!--离线指标详情表-->
                      <radar-block v-if="riskDetail.type === 'indicatrix_result'" title="离线指标详情表">
                        <div v-for="(indicatrix, indicatrixIndex) in riskDetail.indicatrix_result_detail_list"
                          :key="indicatrixIndex">
                          <el-form-item label="规则描述："><span>{{ indicatrix.description }}</span></el-form-item>
                          <el-form-item label="指标结果："><span>{{ indicatrix.result }}</span></el-form-item>
                        </div>
                      </radar-block>
                    </div>
                  </div>
                  <div v-else>
                    <el-empty description="暂无数据"></el-empty>
                  </div>
                </el-form>
              </template>
            </el-table-column>
            <!--风险规则-->
            <el-table-column prop="index" label="序号" align="center"></el-table-column>
            <el-table-column prop="risk_name" label="规则名称" align="center"></el-table-column>
            <el-table-column prop="score" label="风险分数" align="center"></el-table-column>
            <el-table-column prop="decision" label="决策结果" align="center"></el-table-column>
            <el-table-column prop="policy_decision" label="策略决策结果" align="center"></el-table-column>
            <el-table-column prop="policy_mode" label="策略模式" align="center"></el-table-column>
            <el-table-column prop="policy_score" label="策略分数" align="center"></el-table-column>
            <el-table-column prop="policy_name" label="策略名称" align="center"></el-table-column>
            <el-table-column prop="rule_level" label="风险等级" align="center"></el-table-column>
          </el-table>
        </template>
      </radar-block>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from "vuex"
import getReports from "@/mixins/getReports"
import DocumentGenerator from "@/components/viewBar/reports/document-generator"
import radarBlock from "./radar-block"

export default {
  mixins: [getReports],
  components: { DocumentGenerator, radarBlock },
  methods: {
    ...mapActions("userReport/bodyguard", ["getBodyguard"]),
    load (refresh = false) {
      this.getBodyguard(refresh).then(res => res && this.getReports());
    }
  },
  computed: {
    ...mapGetters("userReport/bodyguard", ["data", "data1", "data2"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/bodyguard", ["date"])
  },
  created () {
    this.load();
  }
};
</script>

<style lang="scss" scoped>
.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}

.col {
  margin: 10px;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {}

.bg-huidu {
  background: #b4bdc8;
}

.bg-purple {
  background: #d3dce6;
  font-weight: bold;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  line-height: 40px;
  height: 40px;
  min-height: 36px;
  margin: 8px 0;
  text-align: center;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.block {
  padding: 0;
  margin-bottom: 0px;
}

.block-head {
  margin-top: 20px !important;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
