<template>
  <div>
    <div class="view-bar-body" v-loading="loading" element-loading-text="拼命加载中" style="padding: 20px">
      <view-head :showBtn="showBtn"></view-head>
      <view-tabs :active-index="activeIndex" @change-tab="changeTab" @refresh-tab="refreshTab"></view-tabs>
      <div class="view-bar-body-content">
        <!-- 实名认证 -->
        <view-verify v-if="activeIndex === ''" />

        <!-- 全景雷达 -->
        <view-unify v-if="activeIndex === 'UNIFY'" @refresh-tab="refreshTab" ref="view-tab-UNIFY" />
        <!-- 探针C -->
        <view-behavior v-if="activeIndex === 'BEHAVIOR'" @refresh-tab="refreshTab" ref="view-tab-BEHAVIOR" />
        <!-- 探针A -->
        <view-probe-a v-if="activeIndex === 'PROBE_A'" @refresh-tab="refreshTab" ref="view-tab-PROBE_A" />
        <!-- 全景档案 -->
        <view-archives v-if="activeIndex === 'ARCHIVES'" @refresh-tab="refreshTab" ref="view-tab-ARCHIVES" />
        <view-history v-if="activeIndex === 'HISTORY'" @refresh-tab="refreshTab" ref="view-tab-HISTORY" />
        <!-- 天创黑名单 -->
        <view-blacklist v-if="activeIndex === 'BLACKLIST'" @refresh-tab="refreshTab" ref="view-tab-BLACKLIST" />
        <!-- 信贷保镖 -->
        <view-bodyguard v-if="activeIndex === 'BODYGUARD'" @refresh-tab="refreshTab" ref="view-tab-BODYGUARD" />
        <!-- 综合信用 -->
        <view-comprehensive v-if="activeIndex === 'COMPREHENSIVE'" @refresh-tab="refreshTab"
          ref="view-tab-COMPREHENSIVE" />
        <!-- 天狼星报告 -->
        <view-lightning v-if="activeIndex === 'LIGHTNING'" @refresh-tab="refreshTab" ref="view-tab-LIGHTNING" />
        <!-- 星耀报告 -->
        <view-starlight v-if="activeIndex === 'STARLIGHT'" @refresh-tab="refreshTab" ref="view-tab-STARLIGHT" />
        <!-- 高级报告 -->
        <view-purple v-if="activeIndex === 'PURPLE'" @refresh-tab="refreshTab" ref="view-tab-PURPLE" />
        <!-- 个人消费画像Pro -->
        <view-portrait v-if="activeIndex === 'PORTRAIT'" @refresh-tab="refreshTab" ref="view-tab-PORTRAIT" />
        <!-- 探查（综合版） -->
        <view-explored v-if="activeIndex === 'EXPLORED'" @refresh-tab="refreshTab" ref="view-tab-EXPLORED" />
        <!-- 借条综合 -->
        <view-note v-if="activeIndex === 'NOTE'" @refresh-tab="refreshTab" ref="view-tab-NOTE" />
        <!-- 多借条 -->
        <view-more-note v-if="activeIndex === 'MORE_NOTE'" @refresh-tab="refreshTab" ref="view-tab-MORE_NOTE" />
        <!-- SF报告 -->
        <view-sf v-if="activeIndex === 'SF'" @refresh-tab="refreshTab" ref="view-tab-SF" />
        <!-- 逾期风险报告 -->
        <view-dk v-if="activeIndex === 'DK'" @refresh-tab="refreshTab" ref="view-tab-DK" />
        <!-- 新版反欺诈 -->
        <view-anti-fraud v-if="activeIndex === 'ANTI_FRAUD'" @refresh-tab="refreshTab" ref="view-tab-ANTI_FRAUD" />
        <!-- 排雷手 -->
        <view-mine-clearance v-if="activeIndex === 'MINE_CLEARANCE'" @refresh-tab="refreshTab"
          ref="view-tab-MINE_CLEARANCE" />
        <!-- 新版多头借贷 -->
        <view-lend v-if="activeIndex === 'LEND'" @refresh-tab="refreshTab" ref="view-tab-LEND" />
        <!-- 信贷反诈骗 -->
        <view-credit v-if="activeIndex === 'CREDIT'" @refresh-tab="refreshTab" ref="view-tab-CREDIT" />
        <!-- 流水报告 -->
        <view-flow v-if="activeIndex === 'FLOW'" @refresh-tab="refreshTab" ref="view-tab-FLOW" />
      </div>
    </div>
  </div>
</template>
<script>
import viewHead from "./reports/view-head"
import viewTabs from "./reports/view-tabs"
import viewVerify from "./reports/view-verify"

import viewUnify from "./reports/view-unify"
import viewBehavior from "./reports/view-behavior"
import viewArchives from "./reports/view-archives"
import viewHistory from "./reports/view-history"
import viewBlacklist from "./reports/view-blacklist"
import viewBodyguard from "./reports/view-bodyguard"
import viewComprehensive from "./reports/view-comprehensive"
import viewLightning from "./reports/view-lightning"
import viewStarlight from "./reports/view-starlight"
import viewPurple from "./reports/view-purple"
import viewPortrait from "./reports/view-portrait"
import viewExplored from "./reports/view-explored"
import viewNote from "./reports/view-note"
import viewMoreNote from "./reports/view-more-note"
import viewSf from "./reports/view-sf"
import viewDk from "./reports/view-dk"
import viewAntiFraud from "./reports/view-anti-fraud"
import viewMineClearance from "./reports/view-mine-clearance"
import viewLend from "./reports/view-lend"
import viewCredit from "./reports/view-credit"
import viewProbeA from "./reports/view-probe-a"
import viewFlow from "./reports/view-flow"

import view from "./view.js"

import code from "@/mixins/sms"
import service from "./api"
import tabs from "./tabs"
import { mapActions, mapMutations, mapState } from "vuex"

export default {
  mixins: [code, view],
  props: {
    showView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showBtn: false,
      width: "90%",
      tmwidth: "25%"
    };
  },
  components: {
    viewHead,
    viewTabs,
    viewVerify,
    viewUnify,
    viewBehavior,
    viewArchives,
    viewHistory,
    viewBlacklist,
    viewBodyguard,
    viewComprehensive,
    viewLightning,
    viewStarlight,
    viewPurple,
    viewPortrait,
    viewExplored,
    viewNote,
    viewMoreNote,
    viewSf,
    viewDk,
    viewAntiFraud,
    viewMineClearance,
    viewLend,
    viewCredit,
    viewProbeA,
    viewFlow
  },
  created() {
    this.getDetail()
    if (document.documentElement.clientWidth < 480) {
      this.width = "95%";
      this.tmwidth = "90%";
    }
  },
  computed: {
    ...mapState("userReport", ["activeIndex", "price", "loading"]),
    ...mapState("userReport/verify", ["userInfo", "desensitizationData"]),
  },
  methods: {
    ...mapMutations("userReport", [
      "setIndex",
      "setRange",
      "setIsBlack",
      "setBlackTag",
      "setShowBlack",
    ]),
    ...mapActions("userReport", ["checkPrice"]),
    ...mapActions("userReport/verify", [
      "getMapList",
      "drawMap",
      "getLoginData",
      "getVerify",
      "getList"
    ]),
    changeTab(index, refresh = false, request = false) {
      // 查找 refs
      const refTag = `view-tab-${index}`
      const trigger = () => {
        // 切换标签页
        this.setIndex(index);
        // 如果 tab 存在，并且需要刷新
        if (refresh && this.$refs.hasOwnProperty(refTag)) {
          const target = this.$refs[refTag];
          // 判断 tab 是否包含 load 方法
          if (
            target &&
            target.hasOwnProperty("load") &&
            typeof target.load === "function"
          ) {
            // 调用 load
            target.load(true)
          }
        }
      }
      const tab = tabs.filter((i) => i.id === index).pop()
      if (tab && tab.price > 0) {
        if (request) {
          tab.preview = false
        }
        if (tab.preview) {
          trigger()
        } else {
          this.checkPrice({
            tab: tab,
            refresh,
          }).then((res) => {
            res && trigger()
          })
        }
      } else {
        trigger()
      }
    },
    refreshTab(index) {
      this.changeTab(index || this.activeIndex, true, true)
    },
    getDetail() {
      let flId = this.$route.query.flId;
      return service.superInfo({ superUserId: this.getId() }).then((res) => {
        service.detail(flId).then((res) => {
          document.title = `${res.username} - 查看报告`
          this.handleView(res)
          this.getLoginData()
          this.getList({ pageNum: 1, pageSize: 10 })
          // 处理黑名单显示
          this.setIsBlack(res.blacklist)
          this.setBlackTag(res.blacklistReasons)
          this.setShowBlack(true)
        })
      })
    },
    getId() {
      let storage = sessionStorage.getItem("xinyou_sh_user")
      let user = JSON.parse(storage) || {}
      let { parentId = null, id = null } = user
      // 子账户
      if (parentId) return parentId
      return id
    }
  },
};
</script>
<style lang="scss">
.view-bar {
  position: relative;

  &-btn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 25px;
    cursor: pointer;
  }

  &-body {
    // background-color: rgba(247, 248, 252, 1);
    padding: 10px;

    &-content {
      padding: 20px;
      overflow: hidden;
      background-color: rgba(247, 248, 252, 1);
    }
  }

  .tuomin {
    position: absolute;
    top: 95px;
    right: 40px;
  }
}

.denses {
  .code {
    position: relative;

    .el-input {
      width: 90%;
    }

    .code-btn {
      position: absolute;
      right: 60px;
    }
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .el-dialog__body {
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .view-bar .tuomin {
    top: 3.5rem;
  }
}

@media screen and (max-width: 480px) {
  .el-message-box {
    width: 80% !important;
  }

  .view-bar-body {
    padding: 15px !important;
  }
}
