<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">探针A报告</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>

    <el-row :gutter="20">
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div class="titles">查询结果</div>
          <span>{{ data[0].result_code }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div class="titles">最大逾期金额</div>
          <span>{{ data[0].max_overdue_amt }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div class="titles">最长逾期天数</div>
          <span>{{ data[0].max_overdue_days }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div class="titles">最近逾期时间</div>
          <span>{{ data[0].latest_overdue_time }}</span>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div class="titles">当前逾期机构数</div>
          <span>{{ data[0].currently_overdue }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div class="titles">当前履约机构数</div>
          <span>{{ data[0].currently_performance }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div class="titles">异常还款机构数</div>
          <span>{{ data[0].acc_exc }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <div class="titles">睡眠机构数</div>
          <span>{{ data[0].acc_sleep }}</span>
        </div>
      </el-col>
    </el-row>

    <radar-block title="结果详情" style="display: none;">
      <div class="radar-behavior">
        <el-table border :data="data" style="width: 100%" header-row-class-name="light-head"
          :row-class-name="tableRowClassName">
          <el-table-column prop="result_code" label="探查结果编码" align="center"></el-table-column>
          <el-table-column prop="max_overdue_amt" label="最大逾期金额" align="center"></el-table-column>
          <el-table-column prop="max_overdue_days" label="最长逾期天数" align="center"></el-table-column>
          <el-table-column prop="latest_overdue_time" label="最近逾期时间" align="center"></el-table-column>
          <el-table-column prop="currently_overdue" label="当前逾期机构数" align="center"></el-table-column>
          <el-table-column prop="currently_performance" label="当前履约机构数" align="center"></el-table-column>
          <el-table-column prop="acc_exc" label="异常还款机构数" align="center"></el-table-column>
          <el-table-column prop="acc_sleep" label="睡眠机构数" align="center"></el-table-column>
        </el-table>
      </div>
    </radar-block>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import radarBlock from "./radar-block"
import getReports from "@/mixins/getReports"
import DocumentGenerator from "@/components/viewBar/reports/document-generator"
let tag = 'probeA'
export default {
  mixins: [getReports],
  components: {
    DocumentGenerator, radarBlock
  },
  methods: {
    ...mapActions("userReport/" + tag, ["getData"]),
    load(refresh = false) {
      this.getData(refresh).then((res) => res && this.getReports())
    }
  },
  computed: {
    ...mapGetters("userReport/" + tag, ["data"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/" + tag, ["date"])
  },
  created() {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.el-card__body {
  padding: 5px !important;
}

.titles{
  font-size: 14px;
  font-weight: bold;
}

.name {
  font-weight: bold;
  color: #000;
  font-size: 14px;
  white-space: normal;
  display: inline-block;
  width: max-content;
}

.cbody {
  background-color: #f8f8f8;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.tag-red {
  color: rgb(185, 12, 12);
  text-align: center;
  float: right;
  border: 1px solid #efa3a3;
  background-color: #f7e1e1;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.tag-green {
  color: rgb(103, 193, 57);
  text-align: center;
  float: right;
  border: 1px solid rgb(103, 193, 57);
  background-color: rgb(223, 234, 218);
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  width: 48px;
}

.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}

.el-row {
  margin-bottom: 20px;
  font-size: 14px;
  color: #000;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  // border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #ecf2f9;
  border: 1px solid #e9ebf1;
  font-weight: bold;
  font-size: 13px !important;
}

.bg-purple-whilt {
  background: #fff;
  // border: 1px solid #e9ebf1;
  line-height: 50px !important;
  min-height: 50px !important;
}

.red {
  color: red;
}

.grenn {
  color: green;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  // border-radius: 4px;
  min-height: 42px;
  line-height: 42px;
  padding: 15px 0;
}
.grid-content span{
  display: block;
  font-size: 25px;
  color: red;
  font-weight: 400;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.el-col-3 {
  width: 14.2857% !important;
}

.el-col-5 {
  width: 20% !important;
}

.title2 {
  display: block;
  width: 100%;
  text-align: left;
  line-height: 50px;
  font-size: 15px;
  font-weight: bold;
}

.shili {
  text-align: center;
  font-weight: 400;
  background-color: #527db8;
  color: #fff;
}
</style>