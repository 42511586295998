<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">信用综合报告</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>

    <!-- <el-row style="height: 150px;"> 
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <div class="card-img"><img src="@/assets/img/afs.png"></div>
        </div>
      </el-col>
      <el-col :span="16">
        <el-descriptions class="margin-top" title="无边框列表" :column="3" :size="size">
          <template slot="extra">
            <el-button type="primary" size="small">操作</el-button>
          </template>
          <el-descriptions-item label="用户名">kooriookami</el-descriptions-item>
          <el-descriptions-item label="手机号">18100000000</el-descriptions-item>
          <el-descriptions-item label="居住地">苏州市</el-descriptions-item>
          <el-descriptions-item label="备注">
            <el-tag size="small">学校</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="联系地址">江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item>
        </el-descriptions>
      </el-col>

    </el-row> -->

    <div class="block c-bg-white">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">风险建议：</span><span></span>
        <span class="c-red ft-bold ft-sz-15">{{ data1 }}</span>
      </div>
    </div>
      <el-row>
        <el-col :span="62">
          <div class="grid-content bg-weimz">极低风险</div>
        </el-col>
        <el-col :span="62">
          <div class="grid-content bg-huidu">低风险</div>
        </el-col>
        <el-col :span="62">
          <div class="grid-content bg-guanzhu">中风险</div>
        </el-col>
        <el-col :span="62">
          <div class="grid-content bg-gaowei">高风险</div>
        </el-col>
        <el-col :span="62">
          <div class="grid-content bg-jihei">极高风险</div>
        </el-col>
      </el-row>

    <div class="block c-bg-white">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">行为雷达</span><span></span>
      </div>
    </div>
      <div v-for="(item, index) in comprehensives" :key="index" :style="{ backgroundColor: item.color }">
        <span>{{ item.name }}：</span><span>{{ item.value }}</span>
      </div>


    <div class="block c-bg-white">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">3c租赁</span><span></span>
      </div>
    </div>
    {{ JSON.stringify(loans) }}

    <div class="block c-bg-white">
      <div class="block-head fx-v-center">
        <div class="line"></div>
        <span class="c-black ft-bold ft-sz-15">法院报告</span><span></span>
      </div>
    </div>
    {{ JSON.stringify(courts) }}

    <radar-block title="" style="display: none;">..</radar-block>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
import radarBlock from "./radar-block";

export default {
  mixins: [getReports],
  components: { DocumentGenerator, radarBlock },
  methods: {
    ...mapActions("userReport/blacklist", ["getBlacklist"]),
    ...mapActions("userReport/comprehensive", ["getComprehensive"]),
    ...mapActions("userReport/loan", ["getLoan"]),
    ...mapActions("userReport/court", ["getCourt"]),
    load(refresh = false) {
      this.getBlacklist(refresh).then((res) => res && this.getReports());
      this.getComprehensive(refresh).then((res) => res && this.getReports());
      this.getLoan(refresh).then((res) => res && this.getReports());
      this.getCourt(refresh).then((res) => res && this.getReports());
    },
  },
  computed: {
    ...mapGetters("userReport/blacklist", ["data1"]),
    ...mapGetters("userReport/comprehensive", ["comprehensives"]),
    ...mapGetters("userReport/loan", ["loans"]),
    ...mapGetters("userReport/court", ["courts"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/blacklist", ["date"]),
  },
  created() {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.grid-content {
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
}
.c-bg-white{
  margin-top: 50px;
  margin-bottom: 20px;
}

.bg-jihei {
  background: #e9504a;
}

.bg-gaowei {
  background: #f2ad57;
}

.bg-guanzhu {
  background: #5ea6f8;
}

.bg-huidu {
  background: #69ccca;
}

.bg-weimz {
  background: #8acf56;
}
.el-col-62{
  font-weight: bold;
    width: 20% !important;
}
.ft-sz-15{
  font-size: 24px;
}

.card-img img{
  width: 130px;
  height: 130px;
}

.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}
</style>
