<template>
  <div class="probe document-view">
    <document-generator />
    <div class="jilu">
      <p class="title ft-bold">多借条记录</p>
      <p>
        <span>上次生成时间：{{ date }}</span><button class="refreshBtn" size="small" @click="$emit('refresh-tab')">
          刷新报告
        </button>
        <span>￥{{ price }}/次</span>
      </p>
    </div>
    <!--内容-->
    <div>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane v-for="item in typeList" :key="item.code" :label="item.name" :name="item.code">
          <div v-if="data && data[item.code]">
            <div class="response-num-block fx-m-between">
            <num-block title="累计借入金额" :num="data[item.code].V1" unit="元"></num-block>
            <num-block title="待还总额" :num="data[item.code].V2" unit="元"></num-block>
            <num-block title="大于3天逾期总额" :num="data[item.code].V3" unit="元"></num-block>
            <num-block title="大于7天逾期总额" :num="data[item.code].V4" unit="元"></num-block>
            <num-block title="累计借入人数" :num="data[item.code].V5" unit="人"></num-block>
            <num-block title="累计借入笔数" :num="data[item.code].V6" unit="笔"></num-block>
            <num-block title="大于3天逾期次数" :num="data[item.code].V7" unit="次"></num-block>
            <num-block title="大于7天逾期次数" :num="data[item.code].V8" unit="次"></num-block>
          </div>

            <el-table :data="data[item.code].data" style="width: 100%">
              <el-table-column prop="C1" label="出借人" width="180">
              </el-table-column>
              <el-table-column prop="C2" label="金额" width="180">
              </el-table-column>
              <el-table-column prop="C3" label="借款时间">
              </el-table-column>
              <el-table-column prop="C4" label="还款时间">
              </el-table-column>
              <el-table-column prop="C5" label="状态">
              </el-table-column>
            </el-table>
          </div>
          <el-empty v-else description="描述文字"></el-empty>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import getReports from "@/mixins/getReports";
import DocumentGenerator from "@/components/viewBar/reports/document-generator";
import numBlock from "./num-block";

export default {
  mixins: [getReports],
  components: {
    DocumentGenerator, numBlock
  },
  data() {
    return {
      activeName: 'ssq',
      typeList: [{ code: 'ssq', name: '速速签' }, { code: 'yzhy', name: '一纸合约' }, { code: 'zixin', name: '新姿信' },
      { code: 'yuntiao', name: '云条' }, { code: 'lixin', name: '立信契约' }
      ]
    }
  },
  methods: {
    ...mapActions("userReport/moreNote", ["getMoreNote"]),
    load(refresh = false) {
      this.getMoreNote(refresh).then((res) => res && this.getReports())
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    }
  },
  computed: {
    ...mapGetters("userReport/moreNote", ["data"]),
    ...mapState("userReport", ["uid", "price"]),
    ...mapState("userReport/moreNote", ["date"])
  },
  created() {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
.probe {
  background-color: #fff;
  padding: 20px 22px 50px;
  position: relative;

  .with-margin {
    margin-bottom: 10px;
  }

  .jilu {
    width: 100%;
    height: 114px;
    background: #ffffff;
    padding: 20px 0;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      color: #000000;
    }

    .refreshBtn {
      background-color: #f56c6c;
      padding: 3px 5px;
      color: #ffffff;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      margin: 0 10px;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 480px) {
  .score .fx-m-between {
    display: block;

    .score-card {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .response-num-block {
    display: block;
  }
}
</style>